import { forwardRef, Component, Input, EventEmitter, Output, OnInit, ChangeDetectorRef, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { ActionsSubject } from '@ngrx/store';
import { ActionTrackableComponent } from '../action-trackable/action-trackable.component';
import { UserTrackingService } from 'core/services';
import { getUrlWithProtocol, isCorrectUrl } from 'core/utilities';

@Component({
  selector: 'core-input-url',
  templateUrl: './input-url.component.html',
  styleUrls: ['./input-url.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputUrlComponent),
      multi: true,
    },
  ],
})
export class InputUrlComponent extends ActionTrackableComponent implements ControlValueAccessor, OnInit {
  url: string;
  isCorrectUrl = false;
  isReadOnly: boolean;
  @Input() showChangeStatusIcon: boolean;
  @Input() isForObjectProperties: boolean;
  @Input() showConflictStatusIcon: boolean;
  @Output() checkedListChanged: EventEmitter<any> = new EventEmitter();
  @Input() checkedValue: boolean;

  private onChange: any;
  private onTouch: any;

  constructor(changeDetector: ChangeDetectorRef,
    private injector: Injector,
    actionsSubject$: ActionsSubject,
    private userTrackingService: UserTrackingService) {
    super(actionsSubject$, changeDetector);
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
  }

  writeValue(url: string): void {
    this.url = url;
    this.isCorrectUrl = isCorrectUrl(this.url);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(value: boolean): void {
    this.isReadOnly = value;
  }

  onChangeUrl(value: string): void {
    this.url = value;
    this.onChange(value);
    this.isCorrectUrl = isCorrectUrl(this.url);
  }

  openUrl(target: HTMLElement): void {
    target.blur();
    window.open(getUrlWithProtocol(this.url), '_blank');
  }

  onFocused() {
    if (this.ngControl.name) {
      this.userTrackingService.onActFormField('focused', `${this.ngControl.name}`, this.editObjectId);
    }
  }

  onFocusout() {
    if (this.ngControl.name) {
      this.userTrackingService.onActFormField('focusout', `${this.ngControl.name}`, this.editObjectId);
    }
  }
}
