import { ILinks } from "core/models";

export function convertSocketLinksToILinks(linksFromEvent: { rel: string, href: string }[]): ILinks {
    try {
        linksFromEvent = linksFromEvent || [];
        return linksFromEvent.reduce((res, link) => {
            res[link.rel] = { href: link.href };
            return res;
        }, {});
    } catch (er) {
        return {};
    }
}