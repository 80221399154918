import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WindowEventsService } from 'core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'admin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService,
    private windowEventsService: WindowEventsService
  ) {
    this.translateService.setDefaultLang('en');
    this.listenToResizeEvent();
  }

  listenToResizeEvent() {
    this.windowEventsService.resize$.pipe(takeUntil(this.destroy$)).subscribe(_ => this.windowEventsService.windowRef.location.reload());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
