import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent, LoggedOutComponent, LoginContainerComponent, NotFoundComponent, SessionTimeoutComponent } from 'core/containers';
import { AdminAuthenticatedGuardService } from './decision-first/auth/admin-authenticated-guard.service';


const routes: Routes = [
  {
    canActivate: [AdminAuthenticatedGuardService],
    loadChildren: () => import('./decision-first/decisionFirst.module').then(m => m.DecisionFirstModule),
    path: 'decision-first',
  },
  { path: '', pathMatch: 'full', redirectTo: 'decision-first' },
  { path: 'callback', component: CallbackComponent },
  { path: 'loggedOut', component: LoggedOutComponent },
  { path: 'sessionTimeout', component: SessionTimeoutComponent },
  { path: 'login', component: LoginContainerComponent, data : { displayVersionInformation : false }},
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
