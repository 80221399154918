<label *ngIf="isForObjectProperties" class="urlLabel" for="url">{{ 'resources.url' | translate }}</label>
<label *ngIf="!isForObjectProperties" for="url">{{ 'resources.url' | translate }}</label>
<i *ngIf="showChangeStatusIcon" class="eva eva-edit-2-outline status-icon status-update"></i>
<i *ngIf="showConflictStatusIcon" class="eva eva-alert-triangle status-conflict"></i>
<div class="d-flex align-items-center">
  <div class="input-group mb-3 flex-1">
    <div class="form-control-container">
      <input type="checkbox" *ngIf="showConflictStatusIcon" class="conflict-checkbox" [checked]="checkedValue"
        (change)="checkedListChanged.emit({ controlName: 'url', value: $event.target.checked })">
      <input type="text" id="url" class="form-control" [value]="url" (input)="onChangeUrl($event.target.value)"
        [disabled]="isReadOnly" (focus)="onFocused()" (blur)="onFocusout()" />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary btn-url" (click)="openUrl($event.target)" [disabled]="!isCorrectUrl">
          {{ 'resources.open' | translate }}
        </button>
      </div>
    </div>
  </div>
  <img src="assets/images/user.png" class="ml-2" *ngIf="activeUserOnField" [wjTooltip]="activeUserOnField" width="15"
    height="15">
</div>