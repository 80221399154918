import { HttpBackend, HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoginRequest } from 'core/models';
import { IGNORE_AUTHENTICATION_TOKEN_HEADER_KEY, IGNORE_AUTHENTICATION_TOKEN_HEADER_VALUE, rootActions, rootSelectors } from 'core/root-store';
import { IState } from 'core/root-store';
import { ErrorHandlingService, ExternalConfigService, MessageService } from 'core/services';
import { HealthStompService, HealthCheckStompService } from 'core/stomp-services';
import { getUrlWithoutProtocol, getUrlWithProtocol } from 'core/utilities';
import { combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ILoginContainerState, LoginContainerOptions } from './login-container.const';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'core-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [HealthStompService, HealthCheckStompService, ErrorHandlingService]
})
export class LoginContainerComponent implements OnInit, OnDestroy {
  displayVersionInformation: boolean;
  environmentName: string;
  isForgotPasswordDisplayed = false;
  loginContainerState$: Observable<ILoginContainerState>;
  getUrlWithoutProtocol = getUrlWithoutProtocol;
  getUrlWithProtocol = getUrlWithProtocol;
  showForgotPasswordOption: boolean;
  showRememberOption: boolean = false;
  isOnline: boolean = true;
  networkStateSubscriptions: Subscription = new Subscription();
  isServerDown: boolean = false;
  isStatusZeroError: boolean = false;
  subscriptions: Subscription = new Subscription();
  private _http: HttpClient;
  somethingWentWrongMessages: string[] = ['We are working on getting this fixed as soon as we can.'];
  description: string = "";

  constructor(private store: Store<IState>, private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef, private healthStompService: HealthStompService,
    private healthCheckStompService: HealthCheckStompService, private messageService: MessageService,
    private errorHandlingService: ErrorHandlingService, httpBackend: HttpBackend, private externalConfigService: ExternalConfigService) {
    this.loginContainerState$ = combineLatest([
      this.store.select(rootSelectors.getAuthenticationState),
      this.store.select(rootSelectors.getVersionInformation)
    ])
      .pipe(
        map(([userState, versionInformation]) => {
          return {
            authenticationInProgress: userState.authenticationInProgress,
            authenticationError: userState.authenticationError,
            authorizationError: userState.authorizationError,
            forgotPasswordInProgress: userState.forgotPasswordInProgress,
            versionInformation: versionInformation.information,
            supportLink: versionInformation.supportLink,
            wSO2Error: userState.wSO2Error,
            emailNotValidError: userState.emailNotValidError,
            userDisabled: userState.userDisabled,
            customerExpired: userState.customerExpired
          };
        }));

    this._http = new HttpClient(httpBackend);
  }

  ngOnInit() {
    this.environmentName = this.externalConfigService.getExternalConfiguration()['environmentName'];
    this.store.dispatch(new rootActions.LoadVersionInformation());
    this.store.dispatch(new rootActions.UpdateErrorState());
    this.subscribeRouteData();
    if (this.externalConfigService.getExternalConfiguration()['identityManagement'] == "AD") {
      this.showForgotPasswordOption = false
    } else {
      this.showForgotPasswordOption = true
    }
    this.listenNetworkState();
    this.listenErrorState();
    this.checkForAutologOutMessage();
  }

  listenErrorState() {
    this.subscriptions.add(this.errorHandlingService.errorState$.subscribe(errorState => {
      if (this.errorHandlingService.isOnline !== this.isOnline) {
        this.setOnlineState(this.errorHandlingService.isOnline);
      } else if (errorState.isServerDown === true) {
        this.setServerDownError();
      } else if (errorState.isStatusZeroError === true) {
        this.setStatusZeroError();
      } else {
        if (this.isServerDown || this.isStatusZeroError) {
          this.isServerDown = this.isStatusZeroError = false;
          this.changeDetectorRef.detectChanges();
        }
      }
    }));
    this.subscriptions.add(this.errorHandlingService.serverStateCheckTimeout$.subscribe(errorState => {
      if (errorState.isServerDown === true) {
        this.setServerDownError();
      } else {
        this._http.get(`${this.externalConfigService.getURL()}dfmInformations`, {
          headers: { [IGNORE_AUTHENTICATION_TOKEN_HEADER_KEY]: IGNORE_AUTHENTICATION_TOKEN_HEADER_VALUE }
        }).subscribe((res: any) => {
          if (res.status === 0) {
            this.setStatusZeroError();
          } else if (res.status === 200) {
            this.errorHandlingService.resetErrorState();
          }
        });
      }
    }));
  }

  setServerDownError() {
    this.isServerDown = true;
    this.somethingWentWrongMessages = ['Server is unreachable'];
    this.changeDetectorRef.detectChanges();
  }

  setStatusZeroError() {
    this.isStatusZeroError = true;
    this.somethingWentWrongMessages = ['Refresh the page', 'Please check your internet connection', 'Contact your network admin (for firewall blocking or filtering)'];
    this.description = "Try the following:";
    this.changeDetectorRef.detectChanges();
  }

  subscribeRouteData(): void {
    this.route.data
      .pipe(
        first()
      )
      .subscribe((options: LoginContainerOptions) => {
        this.displayVersionInformation = options.displayVersionInformation;
      });
  }

  onSendLogin(loginRequest: LoginRequest): void {
    this.store.dispatch(new rootActions.StartValidation(loginRequest));
  }

  onSendForgotPassword(email: string): void {
    this.store.dispatch(new rootActions.ForgotPassword(email));
  }

  onToggleForm(): void {
    this.isForgotPasswordDisplayed = !this.isForgotPasswordDisplayed;
  }

  listenNetworkState() {
    this.networkStateSubscriptions.add(fromEvent(window, 'offline')
      .subscribe(e => {
        fetch(`${this.externalConfigService.getURL()}healths`).then(res => {
          this.setOnlineState(res.status >= 200 && res.status < 300);
        }).catch(err => {
          this.setOnlineState(false);
        });
      }));
    this.networkStateSubscriptions.add(fromEvent(window, 'online')
      .subscribe(e => {
        const controller = new AbortController();
        let timeoutId = setTimeout(() => controller.abort(), 3000);
        fetch(`${this.externalConfigService.getURL()}healths`, { signal: controller.signal }).then(res => {
          clearTimeout(timeoutId);
          this.setOnlineState(res.status >= 200 && res.status < 300);
        }).catch(err => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => controller.abort(), 3000);
          fetch(this.externalConfigService.getExternalConfiguration()['internetConnectivityTestUrl'], { signal: controller.signal }).then(res => {
            this.setOnlineState(res.status >= 200 && res.status < 300);
          }).catch(err => {
            this.setOnlineState(false);
          });
        });
      }));
  }

  setOnlineState(isOnline: boolean) {
    this.isOnline = isOnline;
    this.errorHandlingService.setOnlineState(isOnline);
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.networkStateSubscriptions.unsubscribe();
  }

  checkForAutologOutMessage(): void {
    let logoutMsg: any = localStorage.getItem("logoutMessage");
    if (!logoutMsg) return;
    localStorage.removeItem("logoutMessage");
    const messageData: { message: string, title: string } = JSON.parse(logoutMsg);
    this.messageService.handleCustomError(messageData.message, messageData.title, 5000);
  }
}
