import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as wjcCore from '@mescius/wijmo';

@Component({
  selector: 'core-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor {
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() gotFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() lostFocus: EventEmitter<any> = new EventEmitter<any>();
  @Input() dateFormat: string = "yyyy-MM-dd";
  @Input() placeholder: string = "YYYY-MM-DD";
  dateValue: Date = null;
  private onChange: any;
  private onTouch: any;
  @Input() isRequired: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() min: Date | null;
  @Input() max: Date | null;

  writeValue(value: Date): void {
    this.dateValue = this.parseToDate(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  callOnChange(): void {
    if (typeof this.onChange === 'function') {
      this.onChange(this.formatDateToString(this.dateValue));
    } else {
      this.valueChanged.emit(this.formatDateToString(this.dateValue));
    }
  }

  formatDateToString(value: Date): string | null {
    if (value instanceof Date) {
      return wjcCore.Globalize.format(value, this.dateFormat);
    }
  }

  isValidDateFormat(dateString) {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!regEx.test(dateString)) return false;
    let d = new Date(dateString);
    let dNum = d.getTime();
    if (!dNum && dNum !== 0) return false;
    return d.toISOString().slice(0, 10) === dateString;
  }

  parseToDate(value: Date | string): Date {
    if (value === null || value instanceof Date) return value as Date;
    else if (this.isValidDateFormat(`${value}`)) return new Date(value);
    return null;
  }

  setDisabledState(isDisabled: boolean) {
    this.isReadOnly = !!isDisabled;
  }
}