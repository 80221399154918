import { Resource } from "@lagoshny/ngx-hateoas-client";
import { Customer } from "./customer.model";
import { Group } from "./group.model";
import { Tag } from "./tag.model";
import { USER_NAMAGEMENT_CLASS_NAMES } from "./types.model";

export class User extends Resource {
  readonly className: string = USER_NAMAGEMENT_CLASS_NAMES.User;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isAdmin: boolean;
  isStandard: boolean;
  isActiveReader: boolean;
  isDisabled: boolean;
  type: UserType;
  picture: string;
  defaultTag: Tag;
  groups: Group[];
  account: Customer;
  createdBy: string;
  currentTag: CurrentTag;
  isDeveloperView?: boolean;
  userPreferredProject: { preferredProject: any, preferredBranchForProject: any };
}

export interface CurrentTag {
  countTagEntities: number;
  id: string;
  name: string;
  parentTagId: string;
  tagType: string;
  _links: CurrentTagLinks;
}

export interface CurrentTagLinks {
  self: {
    href: string;
  };
  tagEntities: {
    href: string;
  };
  tags: {
    href: string;
  };
}
export enum UserType {
  ADMIN = "ADMIN",
  STANDARD = "STANDARD",
  READER = "READER",
  DISABLED = "DISABLED",
  TRIAL = "TRIAL"
}

export type UserRelatedObject = Group;

export class IStateEditUser {
  user: User;
}

export interface IChangePassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface ChangePasswordFormState {
  displayChangePassword: boolean;
  changePasswordError: boolean;
}

export interface ISuperAdminUser {
  isSuperAdminUser: boolean;
}

export enum UserWebsocketActionType {
  UPDATE_USER = "updateUser",
  ADD_USER_TO_GROUP = "addUserToGroup",
  REMOVE_GROUP_FROM_USER = "removeGroupFromUser",
  UPDATE_MERGE_USER = "updateMergeUser",
  UPDATE_ROLE_USER = "updateRoleUser",
}